import React, {useLayoutEffect, useState} from 'react'
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import carImage from "../assets/greenlambo.jpg"
import clsx from 'clsx';
import Dialog from '@mui/material/Dialog';

import Highlight from 'react-highlight'
import hljs from 'highlight.js';
import javascript from 'highlight.js/lib/languages/javascript';
import 'highlight.js/styles/vs2015.css';

const webCheckoutSrc = require('../assets/webCheckout.jpg')
const paxa = require('../assets/paxa.png')
const txnReport = require('../assets/txnReport.png')

const useStyles = makeStyles((theme) => ({
    primaryTitlesWhite: {
        textAlign: 'center',
        paddingTop: '2em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#fafafa',
        position: 'relative'
    },
    primaryTitles: {
        textAlign: 'center',
        paddingTop: '2em',
        paddingLeft: '1em',
        paddingRight: '1em',
        // color: '#24b47e',
        color: '#199280',
        position: 'relative',
        verticalAlign: 'center'
    },
    primaryPara: {
        textAlign: 'center',
        paddingLeft: '1em',
        paddingRight: '1em',
    },
    bannerTitles: {
        textAlign: 'center',
        paddingTop: '1em',
        // color: '#24b47e',
        color: 'white',
        fontSize: '5em',
        [theme.breakpoints.down('sm')]: {
            fontSize: '3em',
            paddingTop: '0.5em',
        },
    },
    carImage: {
        backgroundImage: `url(${carImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        minHeight: '35px',
        borderRadius: '4px'
    },
    container: {
        marginBottom: '100px',
        minHeight: '87vh',
        maxWidth:'100%',
        padding: 0,
    },
    topBanner: {
        background: `linear-gradient(210deg,#8CD987 0%,#1FA595 100%)`,
        paddingBottom: '1em'
    },
    bannerIcon: {
        fontSize: '1em',
        verticalAlign: 'text-top'
    },
    txnReport: {
        maxHeight: '350px',
        boxShadow: '0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35)',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '175px'
        },
    },
    paxa: {
        top: '35%',
        marginLeft: '17em',
        position: 'absolute',
        maxHeight: '280px',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            display: 'inline-block',
            maxHeight: '135px',
            marginLeft: '3em',
            top: '0%',
            left: '0%'
        },
    },
    webCheckout: {
        top: '14%',
        marginLeft: '21em',
        position: 'absolute',
        maxHeight: '350px',
        boxShadow: '0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35)',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            display: 'inline-block',
            maxHeight: '175px',
            marginLeft: '13em',
            marginTop: '-17em',
            top: '0%',
        },
    },
    iphone: {
        zIndex: 2,
        position: 'absolute',
        width: '264px',
        height: '533px',
        padding: '8px',
        borderRadius: '36px',
        background: '#f6f9fc',
        boxShadow: '0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35)',
        userSelect: 'none',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            width: '132px',
            height: '256px',
            padding: '4px',
            fontSize: '8px',
            borderRadius: '17px',
        },
    },
    iphoneGraphic: {
        position: 'relative',
        height: '100%',
        borderRadius: '34px',
        background: '#fff',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '17px',
        },
    },
    checkoutGraphic: {
        minHeight: '24px',
        fontSize: '11px',
        height: '100%',
        padding: '24px 16px',
        borderRadius: '28px',
        background:'#fff',
        letterSpacing: '.2px',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '14px',
            letterSpacing: '.05px',
            padding: '12px 8px',
            fontSize: '7px',
            minHeight:'12px',
        },
    },
    checkoutInput: {
        minHeight: '24px',
        lineHeight: '24px',
        margin: '6px 0 16px',
        color: '#62788d',
        borderRadius: '4px',
        boxShadow: '0 0 0 1px rgba(50,50,93,.07), 0 2px 3px -1px rgba(50,50,93,.12), 0 1px 3px -1px rgba(0,0,0,.12)',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '2px',
            minHeight:'12px',
            lineHeight: '12px',
            margin: '3px 0 8px',
        },
    },
    center: {
        textAlign: 'center'
    },
    cardInput: {
        display: 'grid',
        grid: 'auto/repeat(2,1fr)',
        minHeight: '24px',
        lineHeight: '24px',
        margin: '6px 0 16px',
        color: '#62788d',
        borderRadius: '4px',
        boxShadow: '0 0 0 1px rgba(50,50,93,.07), 0 2px 3px -1px rgba(50,50,93,.12), 0 1px 3px -1px rgba(0,0,0,.12)',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '2px',
            minHeight:'12px',
            lineHeight: '12px',
            margin: '3px 0 8px',
        },
    },
    cardNumber: {
        gap: '0 6px',
        gridArea: 'auto/span 2',
        boxAlign: 'center',
        alignItems: 'center',
        paddingRight: '6px',
        borderBottom: '1px solid #e6ebf1',

        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',

        [theme.breakpoints.down('sm')]: {
            gap: '0 3px',
            paddingRight:'3px',
            paddingLeft: '4px',
        },
    },
    cardExpiry: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '4px',
        },
    },
    cardCvc: {
        paddingRight: '4px',
        borderLeft: '1px solid #e6ebf1',
        whiteSpace: 'nowrap',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '4px',
            paddingRight: '2px'
        },
    },
    cvcIcon: {
        margin: '4px 0 0 4px',
        [theme.breakpoints.down('sm')]: {
            margin: '2px 0 0 2px',
        },
    },
    country: {
        marginBottom: '24px',
        minHeight: '24px',
        lineHeight: '24px',
        margin: '6px 0 16px',
        color: '#62788d',
        borderRadius: '4px',
        boxShadow: '0 0 0 1px rgba(50,50,93,.07), 0 2px 3px -1px rgba(50,50,93,.12), 0 1px 3px -1px rgba(0,0,0,.12)',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '12px',
            minHeight: '12px',
            lineHeight: '12px',
            margin: '3px 0 8px',
            borderRadius: '2px',
        },
    },
    countryDropdown: {
        boxAlign: 'center',
        alignItems: 'center',
        paddingRight: '9px',
        borderBottom: '1px solid #e6ebf1',

        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '4px',
            paddingLeft: '4px',
        },
    },
    addressInput: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',
        borderBottom: '1px solid #e6ebf1',
        marginTop: '4px'
    },
    payBtn: {
        borderRadius: '4px',
        padding: '5px 0',
        textAlign: 'center',
        fontWeight: 425,
        color: '#fff',
        background: '#156573',
        boxShadow: '0 2px 4px -1px rgba(50,50,93,.25), 0 1px 3px -1px rgba(0,0,0,.3)',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '2px',
            padding: '2px 0',
        },
    },
    secondLayer: {
        marginTop: '5em',
        backgroundColor: '#25a794',
        color: '#fafafa',
        padding: '7em',
        [theme.breakpoints.down('sm')]: {
            padding: '3em',
        },
    },
    demoContainer: {
        padding: '1em',
        maxHeight: '35rem',
        margin: '0 auto'
    },
    codeBlock: {
        borderRadius: '14px',
        margin: '0 auto'
    },
    demoBtn: {
        display: 'flex',
        margin: '0 auto',
        backgroundColor: '#156573',
        color: 'white',
        marginTop: '1em',
        '&:hover': {
            pointer: 'cursor',
            backgroundColor: '#108da3',
            color: 'white',
        },
    },
    demoFrame: {
        width: '100%',
        minHeight: '80vh'
    }
}));

const Products = (props) => {
    const classes = useStyles(props)
    const { toggleNavbarWhite, toggleNavbarTransparent } = props
    useLayoutEffect(() => {
        toggleNavbarWhite(true)
        hljs.registerLanguage('javascript', javascript);
        hljs.initHighlightingOnLoad();
    },[])

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
    setOpen(false);
    };

    const [fullWidth, setFullWidth] = React.useState(true);

    const [maxWidth, setMaxWidth] = React.useState('xl');

    return (<Container className={classes.container} >
        <Grid container 
            justify="center" 
            alignContent="center" 
            alignItems="center" 
            direction="row"
            className={classes.content}
        >
            <Grid item xs={12} sm={12} md={12} className={classes.topBanner}>
                <Container maxWidth={'xl'} >
                    <Grid container>
                        <Grid item xs={12} sm={5} md={5}>
                            <Typography className={classes.bannerTitles} variant="h4"><b>
                                Payments ecosystem, engineered for</b><br/><b>growth</b><TrendingUpIcon className={classes.bannerIcon} fontSize={'large'}/><br/>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7} md={7}>
                            <figure className={classes.iphone}>
                                <div className={classes.iphoneGraphic}>
                                    <div className={classes.checkoutGraphic}>
                                        <div className={classes.carImage}></div><br/>
                                        <div className={classes.center}>
                                            <b>Green Lamborghini</b><br/>S $7k per Month<br/><br/>
                                        </div><br/>
                                        <b>Email</b>
                                        <div className={classes.checkoutInput}></div>
                                        <div className={classes.cardInput}>
                                            <div className={classes.cardNumber}>
                                                Number
                                                {/* Visa */}
                                                <svg width="17" viewBox="0 0 17 12">
                                                    <path fill="#FFF" stroke="#000" stroke-opacity=".2" stroke-width=".5" d="M1.6.18h13.8c.79 0 1.42.63 1.42 1.41v8.74c0 .78-.63 1.42-1.41 1.42H1.59c-.78 0-1.41-.64-1.41-1.42V1.6C.18.81.8.18 1.59.18z"></path>
                                                    <path fill="#171E6C" d="M1.98 4.39A5.1 5.1 0 0 0 .7 3.9l.02-.09h1.93c.27 0 .48.1.55.37l.42 2.01.13.6 1.18-2.97h1.28l-1.9 4.35H3.05l-1.07-3.8zm5.17 3.8h-1.2l.75-4.37h1.2l-.75 4.37zm4.37-4.26l-.16.95-.11-.05c-.21-.09-.5-.18-.9-.17-.48 0-.69.19-.7.38 0 .2.27.33.68.54.7.3 1.02.69 1.02 1.19-.01.9-.83 1.48-2.1 1.48-.54 0-1.06-.1-1.34-.22l.16-.99.17.07c.39.17.64.24 1.13.24.34 0 .71-.14.72-.43 0-.19-.16-.33-.63-.54-.45-.22-1.06-.57-1.05-1.2 0-.84.85-1.44 2.03-1.44.47 0 .85.1 1.08.19zm1.6 2.72h1l-.27-1.27-.09-.38-.15.43-.48 1.22zm1.5-2.83l.96 4.37h-1.12l-.14-.65h-1.54l-.26.65h-1.26l1.79-4c.13-.3.34-.36.63-.36h.93v-.01z"></path>
                                                </svg>
                                                {/* Master */}
                                                <svg width="17" viewBox="0 0 17 12">
                                                    <rect width="17" height="12" fill="#0A2540" rx="2"></rect>
                                                    <circle cx="6" cy="6" r="3.6" fill="#ED0006"></circle>
                                                    <circle cx="10.8" cy="6" r="3.6" fill="#F9A000"></circle>
                                                    <path fill="#FF5D00" d="M8.4 3.32a3.6 3.6 0 0 1 0 5.36 3.58 3.58 0 0 1-.08-5.29z"></path>
                                                </svg>
                                                {/* AMEX */}
                                                <svg width="17" viewBox="0 0 17 12">
                                                    <rect width="17" height="12" fill="#016FD0" rx="2"></rect>
                                                    <polygon fill="#FFF" points="9.347 10.101 9.347 5.801 17 5.808 17 6.996 16.115 7.941 17 8.895 17 10.108 15.588 10.108 14.837 9.28 14.092 10.111"></polygon>
                                                    <polygon fill="#016FD0" points="9.858 9.63 9.858 6.275 12.703 6.275 12.703 7.047 10.779 7.047 10.779 7.572 12.657 7.572 12.657 8.332 10.779 8.332 10.779 8.847 12.703 8.847 12.703 9.63"></polygon>
                                                    <polygon fill="#016FD0" points="12.689 9.63 14.263 7.95 12.689 6.275 13.907 6.275 14.869 7.338 15.834 6.275 17 6.275 17 6.301 15.459 7.95 17 9.582 17 9.63 15.822 9.63 14.843 8.556 13.874 9.63"></polygon>
                                                    <polygon fill="#FFF" points="9.704 1.985 11.549 1.985 12.197 3.456 12.197 1.985 14.474 1.985 14.867 3.087 15.261 1.985 17 1.985 17 6.285 7.809 6.285"></polygon>
                                                    <path fill="#016FD0" d="M10.05 2.45L8.56 5.8H9.6l.28-.67h1.52l.28.67h1.05l-1.49-3.35h-1.18zm.13 1.93l.45-1.07.44 1.07h-.89z"></path>
                                                    <polygon fill="#016FD0" points="12.701 5.804 12.701 2.451 14.137 2.457 14.875 4.517 15.619 2.451 17 2.451 17 5.804 16.111 5.812 16.111 3.509 15.272 5.804 14.461 5.804 13.604 3.501 13.604 5.804"></polygon>
                                                </svg>
                                                {/* DISCOVER */}
                                                <svg width="17" viewBox="0 0 17 12">
                                                    <path fill="#FFF" stroke="#000" stroke-opacity=".2" stroke-width=".5" d="M15.58 11.75c.68 0 1.24-.55 1.24-1.24V1.4c0-.32-.14-.64-.37-.87a1.22 1.22 0 0 0-.87-.36H1.42C1.09.18.78.3.55.53a1.3 1.3 0 0 0-.37.89v9.09c0 .32.14.64.37.88.23.23.54.36.87.36h14.16z"></path>
                                                    <path fill="#F27712" d="M8.93 12.07h6.65c.78 0 1.41-.62 1.42-1.4V9a27.44 27.44 0 0 1-8.07 3.07z"></path>
                                                    <path fill="#000" d="M16.41 6.79h-.6l-.68-.9h-.07v.9h-.49V4.56h.73c.57 0 .9.23.9.65 0 .35-.21.57-.57.64l.78.94zm-.72-1.56c0-.21-.17-.33-.47-.33h-.15v.68h.14c.31 0 .48-.12.48-.35zm-2.83-.67h1.38v.37h-.9v.5h.87v.38h-.87v.6h.9v.38h-1.4V4.56h.02zm-1.6 2.28l-1.06-2.3h.54l.67 1.51.68-1.5h.53l-1.07 2.3h-.28zm-4.46 0c-.75 0-1.34-.51-1.34-1.17 0-.65.6-1.17 1.35-1.17.21 0 .39.04.6.13v.52a.88.88 0 0 0-.61-.25c-.47 0-.83.34-.83.77 0 .44.36.77.85.77.23 0 .4-.07.6-.25v.52c-.23.1-.41.13-.62.13zM5.31 6.1c0 .43-.35.73-.88.73-.37 0-.63-.13-.87-.43l.33-.26c.1.2.3.3.53.3.22 0 .38-.15.38-.32 0-.1-.05-.18-.15-.23-.1-.05-.22-.1-.34-.13-.46-.15-.62-.3-.62-.62 0-.36.34-.63.79-.63.28 0 .53.08.74.24l-.25.3a.54.54 0 0 0-.4-.18c-.21 0-.37.11-.37.25 0 .12.1.18.38.28.57.18.73.34.73.7zM2.9 4.56h.5v2.23h-.5V4.56zM1.3 6.79H.59V4.56h.72c.8 0 1.35.46 1.35 1.1 0 .35-.16.66-.45.87-.25.18-.52.26-.9.26zm.57-1.67c-.16-.13-.35-.18-.67-.18h-.13v1.48h.14c.31 0 .5-.06.66-.18a.7.7 0 0 0 0-1.12z"></path>
                                                    <path fill="#F27712" d="M8.79 4.5c-.64 0-1.17.52-1.17 1.15 0 .68.5 1.2 1.17 1.2.66 0 1.18-.52 1.18-1.18 0-.65-.51-1.17-1.18-1.17z"></path>
                                                </svg>
                                            </div>
                                            <div className={classes.cardExpiry}>
                                                MM / YY
                                            </div>
                                            <div className={classes.cardCvc}>
                                                CVC
                                                {/* CVC */}
                                                <svg className={classes.cvcIcon} width="19" viewBox="0 0 19 14">
                                                    <rect width="17" height="12" y="2" fill="#E6EBF1" rx="2"></rect>
                                                    <rect width="13" height="2" x="2" y="4" fill="#FFF" rx="1"></rect>
                                                    <circle cx="14.5" cy="4.5" r="4.5" fill="#B4C2D0"></circle>
                                                    <path fill="#FFF" d="M12.5 5.96V3.03H12c-.04.33-.2.46-.58.5l-.24.01v.51h.66v1.9h.67zm1.61.04c.65 0 1.07-.4 1.07-.87 0-.36-.2-.57-.46-.67a.65.65 0 0 0 .4-.62c0-.48-.38-.84-.99-.84-.63 0-1.02.4-1.09.92l.62.07c.04-.25.2-.43.44-.43.23 0 .37.13.37.32 0 .22-.17.32-.42.32h-.26v.54h.27c.27 0 .46.1.46.33 0 .24-.18.37-.42.37-.25 0-.44-.16-.48-.45l-.65.1c.08.6.54.91 1.14.91zm2.53 0c.6 0 1.07-.43 1.07-1.02 0-.56-.36-.98-.95-.98-.2 0-.37.07-.48.16l.05-.54h1.2v-.58h-1.7l-.14 1.6.6.07c.07-.1.2-.18.37-.18.26 0 .42.2.42.46 0 .27-.2.45-.45.45s-.42-.17-.46-.44l-.65.1c.09.58.52.9 1.12.9z"></path>
                                                </svg>
                                            </div>
                                        </div><br/>
                                        <b>Country</b><br/>
                                        <div className={classes.country}>
                                            <div className={classes.countryDropdown}>
                                                Singapore
                                                {/* Dropdown bar */}
                                                <svg width="9" viewBox="0 0 9 6">
                                                    <polyline fill="none" stroke="#62788D" stroke-width="1.2" points="1 1 4.5 4.5 8 1"></polyline>
                                                </svg>
                                            </div>
                                            <div className={classes.addressInput}>
                                                Postal
                                            </div>
                                        </div>
                                        <div className={classes.payBtn}>
                                            Pay
                                        </div>
                                    </div>
                                </div>
                            </figure>
                            <img src={webCheckoutSrc} className={classes.webCheckout} />
                            <img src={paxa} className={classes.paxa}/>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                    <Container maxWidth={'xl'} >
                        <Grid container>
                            <Grid item xs={12} sm={5} md={5}>
                                <Typography className={classes.primaryTitles} variant="h4">
                                    <CheckCircleOutlineIcon className={classes.bannerIcon} fontSize={'large'} /> Mobile Solutions
                                </Typography>
                                <Typography className={classes.primaryTitles} variant="h4">
                                    <CheckCircleOutlineIcon className={classes.bannerIcon} fontSize={'large'} /> Hosted Web Solutions
                                </Typography>
                                <br/>
                                <Button
                                    onClick={handleClickOpen}
                                    className={classes.demoBtn}
                                >Launch E-Commerce Demo</Button>
                                <Typography className={classes.primaryTitles} variant="h4">
                                    <CheckCircleOutlineIcon className={classes.bannerIcon} fontSize={'large'} /> In-Person Payment Terminals
                                </Typography>
                                <Typography className={classes.primaryTitles} variant="h6">
                                    These out of the box solutions removes responsibilities on your end to adhere to industrial standards and unifies both the online and in-person payments in one ecosystem.
                                </Typography>
                            </Grid>
                            <Grid item xs={7} sm={7} md={7}>
                                
                            </Grid>
                        </Grid>
                    </Container>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.secondLayer}>
                <Container maxWidth={'xl'} >
                    <Grid container>
                        <Grid item xs={12} sm={7} md={7}>
                                <img src={txnReport} className={classes.txnReport}/>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5}>
                            <Typography className={classes.primaryTitlesWhite} variant="h4">
                                An all-in-one ecosystem to <b>streamline</b> your business processes.<br/><br/><br/>So that <b>business analytics</b> is just a 'tap' away.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Container maxWidth={'xl'} >
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography className={classes.primaryTitles} style={{marginTop: '3em'}} variant="h4">
                                Easy Integration For Engineers. For you to be <b>Fast To Market</b> and grow your business.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography className={classes.primaryTitles} variant="h4">
                                Web Checkout Sample
                            </Typography>
                            <div className={classes.demoContainer}>
                                <Highlight className={clsx("javascript", classes.codeBlock)}>
                                    {'//'} FRONTEND{'\n'}
                                    {'//'} Launch the various payment solutions from the front-end and pass in the response to the backend.{`\n`}
                                    const liberalize = require('liberalize-frontend-web-sdk');{'\n'}
                                    const liberalizeWeb = new liberalize.LiberalizeWeb("your_public_key", "environment"); {'//'} environment will be 'staging' for test environment, defaults to live environment if not passed in{'\n'}
                                    await liberalizeWeb.cardElement('id_of_div');{'\n'}{'\n'}
                                    {'//'} Method for onClick Event on Pay button {'\n'}
                                    const createPaymentMethod = async () => {'{\n'}
                                    {'\t'}const response = await liberalizeWeb.cardElementPay() {'//'} The card's paymentMethodId will be in the response {'\n'}
                                    {'}\n'}{'\n'}
                                    {'//'} BACKEND{'\n'}
                                    const liberalize = require('liberalize-backend-nodejs-sdk');{'\n'}
                                    const liberalizeBackend = liberalize.LiberalizeNodeJs("your_private_key", "environment"); {'//'} environment will be 'staging' for test environment, defaults to live environment if not passed in{'\n'}
                                    {'\n'}
                                    {'//'} Create a payment intent from your backend to initialize a payment flow.{'\n'}
                                    let paymentIntent = await liberalizeBackend.createPayment({`{\n`}
                                    {'\t'}amount: 2000, {'//'} amount in the lowest denominator{`\n`}
                                    {'\t'}currency: 'usd',{`\n`}
                                    {'\t'}authorize: true, {'//'} (optional: for immediate authorizzation process{`\n`}
                                    {'\t'}source: 'the_paymentMethodId', {'//'} (optional: required when authorize is set to true{`\n`}
                                    {'\t'}description: 'My first payment',{`\n`}
                                    {'}'});{'\n'}
                                    {'\n'}
                                    {'//'} Capturing the payment.{'\n'}
                                    liberalizeBackend.capturePayment({`{\n`}
                                    {'\t'}paymentId: 'b98ckas0a-11233-4748-bacf-49asdsp954cb', {'//'} paymentId from the paymentIntent{`\n`}
                                    {'}'});{'\n'}
                                    {'\n'}
                                </Highlight>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
        <Dialog
                    fullWidth={true}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={handleClose}
                >
                    <iframe
                        frameBorder="0"
                        className={classes.demoFrame}
                        title="Liberalie E-Commerce Demo"
                        src="https://liberationnetwork.github.io/sample-element-checkout/"
                    ></iframe>
                </Dialog>
    </Container>)
}

export default Products
