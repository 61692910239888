import React, {useLayoutEffect,useRef, Suspense} from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import mapImage from "../assets/worldmap_green.png"

import { Html, OrbitControls, Stage, useProgress } from '@react-three/drei';

import { Canvas } from '@react-three/fiber'

import styled from 'styled-components';

import Globe from './Globe'

const useStyles = makeStyles((theme) => ({
    primaryTitles: {
        textAlign: 'center',
        paddingTop: '2em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#24b47e'
    },
    primaryPara: {
        textAlign: 'center',
        paddingLeft: '1em',
        paddingRight: '1em',
    },
    mapImage: {
        backgroundImage: `url(${mapImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        minHeight: '50vh'
    },
    container: {
        marginBottom: '100px',
        minHeight: '87vh',
        marginTop: '-10vh'
    },
    onePage: {
      maxWidth: '100%',
      minHeight: '100vw',
      minWidth: '100vw',
      backgroud:  '#f0f0f0',
      overflow: 'hidden'
  },
  threeWorld: {
    minHeight: '70vh',
    [theme.breakpoints.up('md')]: {
        marginTop: '-10vh'
    },
}
}));

function Loader() {
  const { active, progress, errors, item, loaded, total } = useProgress();
  return <Html center style={{color: '#24b47e', fontWeight: '700'}}>{progress.toFixed(2)}%</Html>
}

const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

const OurCompany = (props) => {
    const classes = useStyles(props)
    const { toggleNavbarWhite } = props
    useLayoutEffect(() => {
        toggleNavbarWhite(true)
    },[])

    const [expanded, setExpanded] = React.useState('singapore');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const ref = useRef()
    return (<div className={classes.onePage}>
        <Canvas dpr={[1, 2]} camera={{ fov: 50 }} className={classes.threeWorld}>
            <Suspense fallback={<Loader/>}>
                <Stage controls={ref} contactShadowBlur={6}>
                  <Globe />
                </Stage>
            </Suspense>
            <OrbitControls ref={ref} enableZoom={false} autoRotate makeDefault />
        </Canvas>
      <Container className={classes.container} maxWidth="xl" >
        <Grid container 
            justify="center" 
            alignContent="center" 
            alignItems="center" 
            direction="row"
            className={classes.content}
        >
            <Grid item xs={12} sm={12} md={10}>
                <Typography className={classes.primaryTitles} variant="h4"><b>
                    We help companies simplify and transform payments</b><br/><br/>
                </Typography>
                <Typography className={classes.primaryPara} variant="h5">
                    Liberalize is a technology company that builds highly polished products designed to 
                    help transform your payments and ultimately transform your business.
                </Typography><br/>
                <Typography className={classes.primaryPara} variant="h5">Our team comprises of multiple industry experts that are able to understand and translate your business needs. We are experienced in building highly available payments solutions.</Typography><br/><br/>
            </Grid>
            <Grid item xs={12} sm={12} md={10} className={classes.mapImage}></Grid>
            <Grid item xs={12} sm={12} md={8}>
                <Typography className={classes.primaryTitles} variant="h4"><b>Information and Disclosures</b><br/><br/></Typography>
                <Accordion square expanded={expanded === 'singapore'} onChange={handleChange('singapore')}>
                    <AccordionSummary aria-controls="singapored-content" id="singapored-header">
                    <Typography>Singapore</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{textAlign: 'justify'}}>
                        <Typography>
                            Liberalize Pte Ltd is licensed by the Monetary Authority of Singapore to provide Account Issuance Service, Domestic Money Transfer Service, Cross-border Money Transfer Service and Merchant Acquisition Service. Please note that this does not mean you will be able to recover all the money you paid to Liberalize Pte Ltd if Liberalize’s business fails.
                        </Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{textAlign: 'justify'}}>
                        <Typography>
                            Liberalize Pte Ltd provides 24-hour online payment services to its account holders, subject to the maintenance schedule which will be made known in advance to account holders here. 
                        </Typography>
                    </AccordionDetails>
                    <AccordionDetails style={{textAlign: 'justify'}}>
                        <Typography>
                            Our office is located at 3016 Bedok North Avenue 4, #08-31, EASTECH, Singapore 489947. We are opened on weekdays from 9am to 6pm and closed on weekends/public holidays. For any enquiries, please contact us at +65 87311877.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    </Container></div>)
}

export default OurCompany
