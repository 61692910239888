function navDrawers(state ={}, action) {
    console.log('state in drawer reducer', state);
    console.log('action in drawer reducer', action);
    switch (action.type) {
      case 'TOGGLE_DRAWER':
          console.log('toggle drawer action !');
          
          state = {
              ...{isOpen: !state.isOpen} 
          }
        return state
      default:
        return state
    }
  }

export default navDrawers