function navBar(state ={}, action) {
    console.log('state in drawer reducer', state);
    console.log('action in drawer reducer NAVBARRRR', action);
    switch (action.type) {
      case 'TOGGLE_NAVBAR_TRANSPARENT':          
          state = {
              ...{originalNav: true} 
          }
        return state
      case 'TOGGLE_NAVBAR_WHITE':          
        state = {
          ...{originalNav: false} 
        }
      return state
      default:
        return state
    }
  }

export default navBar