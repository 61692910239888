import React, { useLayoutEffect } from 'react'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
const nebula = require("../assets/nebula.jpg");
const useStyles = makeStyles((theme) => ({
    Error: {
        color: 'white',
        textAlign: 'center',
        marginTop: '21%',
        marginLeft: 0,
        height: '70vh',
    },
    link:{
        textDecoration: 'none',
        color: '#1eecb0'
    },
    container: {
        background: `url(${nebula}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        marginLeft: 0,
        marginRight: 0,
        maxWidth: 999999
    },
    textWrapper: {
        backgroundColor: '#00000054',
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    }
}));

const ComingSoon = (props) => {
    const classes = useStyles(props)
    const { toggleNavbarTransparent } = props
    useLayoutEffect(() => {
        toggleNavbarTransparent(true)
    },[])
    return (<Container maxWidth="xl" className={classes.container}>
        <div className={classes.textWrapper}>
        <Box m={2} variant="middle" className={classes.Error}>
            <Typography variant="h1">Coming Soon</Typography>
            <Typography variant="h5">
            <br/>Please contact us via our email: <a className={classes.link} href="mailto:support@liberalize.io">support@liberalize.io</a>
            </Typography>
        </Box>
        </div>
    </Container>)
}

export default ComingSoon
