import { combineReducers } from 'redux'
import users from './users'
import navDrawers from './navDrawers'
import navBar from './navBar'

const mainApp = combineReducers({
    users,
    navDrawers,
    navBar
  })

export default mainApp