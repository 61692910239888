import { connect } from 'react-redux'
import { toggleDrawer } from '../actions/drawers'
import Appbar from '../components/Appbar'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    originalNavState: (state.navBar && state.navBar.originalNav) || false
})
  
const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Appbar)