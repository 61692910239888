import React, {useLayoutEffect,useRef, Suspense} from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';

import alipay from "../assets/paymentSchemes/alipay.png"
import grabpay from "../assets/paymentSchemes/grabpay.png"
import mastercard from "../assets/paymentSchemes/mastercard.png"
import paynow from "../assets/paymentSchemes/paynow.png"
import shopeepay from "../assets/paymentSchemes/shopeepay.png"
import visa from "../assets/paymentSchemes/visa.jpeg"
import wechatpay from "../assets/paymentSchemes/wechatpay.png"


const useStyles = makeStyles((theme) => ({
    pricing: {
      textAlign: 'center',
      border: '1px solid #d8d8d8'
    },
    alipay: {
        backgroundImage: `url(${alipay})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        minHeight: '250px',
        margin: '1em',
    },
    grabpay: {
      backgroundImage: `url(${grabpay})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      minHeight: '250px',
      margin: '1em',
    },
    mastercard: {
      backgroundImage: `url(${mastercard})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      minHeight: '250px',
      margin: '1em',
    },
    paynow: {
      backgroundImage: `url(${paynow})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      minHeight: '250px',
      margin: '1em',
    },
    shopeepay: {
      backgroundImage: `url(${shopeepay})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      minHeight: '250px',
      margin: '1em',
    },
    visa: {
      backgroundImage: `url(${visa})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      minHeight: '250px',
      margin: '1em',
    },
    wechatpay: {
      backgroundImage: `url(${wechatpay})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      minHeight: '250px',
      margin: '1em',
    },
    primaryTitles: {
        textAlign: 'center',
        paddingTop: '2em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#24b47e'
    },
    container: {
        marginBottom: '100px',
        minHeight: '87vh',
        paddingLeft: 0,
        paddingRight: 0
    },
    onePage: {
      maxWidth: '100%',
      minHeight: '100vw',
      minWidth: '100vw',
      backgroud:  '#f0f0f0',
      overflow: 'hidden',
    },
    topBanner: {
      background: `linear-gradient(210deg,#8CD987 0%,#1FA595 100%) no-repeat`,
      paddingBottom: '1em',
      backgroundSize: '100% 77%',
    },
    bannerTitles: {
      textAlign: 'center',
      paddingTop: '1em',
      // color: '#24b47e',
      color: 'white',
      fontSize: '4em',
      [theme.breakpoints.down('sm')]: {
          fontSize: '3em',
          paddingTop: '0.5em',
      },
    },
    greenText: {
      color: '#24b47e'
    },
    leftBox: {
      textAlign: 'center',
      minHeight: '350px',
      marginTop: '10vh',
      background: 'white',
      boxShadow: '0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%)',
      borderRadius: '3px',
    },
    rightBox: {
      textAlign: 'center',
      minHeight: '300px',
      marginTop: '12vh',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
      background: '#16a09e',
      color: 'white',
      boxShadow: '0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%)',
      borderRadius: '3px',
    },
    boxBottomBorders: {
      borderBottom: 'solid 1px #f6f9fc'
    },
    boxTitles: {
      margin: '1em'
    },
    transactionFee: {
      fontSize: '0.3em'
    },
    checkBelow: {
      fontSize: '0.7em'
    },
    rightArrow: {
      verticalAlign: 'middle'
    },
    heavierFontWeight: {
      fontWeight: 500
    },
    smallerFont: {
      color: '#d7dadd',
      fontSize: '0.9em'
    },
    contactSales: {
      background: '#21a695',
      padding: '1.3em',
      '&:hover': {
        background: '#19bda8',
        cursor: 'pointer',
      },
    },
    getStarted: {
      padding: '1.3em',
      '&:hover': {
        background: '#e6ebf1',
        cursor: 'pointer',
      },
    }
}));

const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

const Pricing = (props) => {
    const classes = useStyles(props)
    const { toggleNavbarWhite } = props
    useLayoutEffect(() => {
        toggleNavbarWhite(true)
    },[])

    const [expanded, setExpanded] = React.useState('singapore');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const ref = useRef()
    return (<div className={classes.onePage}>
      <Container className={classes.container} maxWidth="false" >
        <Grid container 
            justify="center" 
            alignContent="center" 
            alignItems="center" 
            direction="row"
            className={classes.content}
        >
            <Grid item xs={12} sm={12} md={12}>
                <Grid container className={classes.topBanner}>
                    <Grid item xs={12} sm={12} md={12} >
                        <Typography className={classes.bannerTitles} variant="h6">
                          Pricing engineered for all businesses
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1}>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <Grid container className={classes.leftBox}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h6" className={classes.boxTitles}><span className={classes.greenText}>INTEGRATED</span></Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Typography>Easily start receiving card payments with <br/>simple, pay-per-use pricing.</Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h2">3.4%<span className={classes.transactionFee}> + S$0.50</span></Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Typography><span className={classes.checkBelow}>Check below for more details.</span></Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} 
                            className={classes.getStarted}
                            onClick={() => {
                              window.location.href = 'https://sso.liberalize.io/#/signup?redirectUrl=https://console.liberalize.io/#/&clientId=1234-1234-1234-1234'
                            }}
                          >
                            <Typography variant="h5">GET STARTED IN MINUTES<ChevronRightIcon className={classes.rightArrow}/></Typography>
                          </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <Grid container className={classes.rightBox}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h6" className={clsx(classes.boxTitles, classes.boxBottomBorders)}>CUSTOMISED</Typography>
                            <Typography><span className={classes.heavierFontWeight}>Customise a solution for your business.</span></Typography>
                            <Typography><span className={classes.smallerFont}>For businesses with large payments volume or unique business models.</span></Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}> </Grid>
                          <Grid item xs={12} sm={12} md={12}> </Grid>
                          
                          <Grid item xs={12} sm={12} md={12} 
                            className={classes.contactSales}
                            onClick={(event) => { 
                              event.preventDefault()
                              window.open('mailto:support@liberalize.io?subject=RE: Customised Solution Sales Request')
                            }}
                          >
                            <Typography  variant="h5">CONTACT SUPPORT<ChevronRightIcon className={classes.rightArrow}/></Typography>
                          </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1}>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={10} className={classes.mapImage}></Grid>
            <Grid item xs={12} sm={12} md={8}>
                <Container maxWidth="md">
                  <Typography className={classes.primaryTitles} variant="h4"><b>Supported Payments By Countries</b><br/><br/></Typography>
                  <Accordion square expanded={expanded === 'singapore'} onChange={handleChange('singapore')}>
                      <AccordionSummary aria-controls="singapored-content" id="singapored-header">
                      <Typography>Singapore Pricings</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{textAlign: 'justify'}}>
                        <Grid container >
                          <Grid item xs={6} sm={6} md={6} className={classes.pricing}>
                              <div className={classes.paynow}></div>
                              <Typography><span>1.0% + S$0.35</span></Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} className={classes.pricing}>
                              <div className={classes.visa}></div>
                              <Typography><span >Coming Soon</span></Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} className={classes.pricing} >
                              <div className={classes.mastercard}></div>
                              <Typography><span >Coming Soon</span></Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} className={classes.pricing} >
                              <div className={classes.grabpay}></div>
                              <Typography><span >3.3% + S$0.35</span></Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} className={classes.pricing} >
                              <div className={classes.shopeepay}></div>
                              <Typography><span >3.3% + S$0.35</span></Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} className={classes.pricing} >
                              <div className={classes.alipay}></div>
                              <Typography><span >2.2% + S$0.35</span></Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} className={classes.pricing} >
                              <div className={classes.wechatpay}></div>
                              <Typography><span >2.2% + S$0.35</span></Typography>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                  </Accordion>
                </Container>
            </Grid>
        </Grid>
    </Container></div>)
}

export default Pricing
