import React, {useLayoutEffect, useEffect} from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import carImage from "../assets/greenlambo.jpg"
import clsx from 'clsx';

import Highlight from 'react-highlight'
import hljs from 'highlight.js';
import javascript from 'highlight.js/lib/languages/javascript';
import 'highlight.js/styles/vs2015.css';

const webCheckoutSrc = require('../assets/webCheckout.jpg')
const paxa = require('../assets/paxa.png')
const txnReport = require('../assets/txnReport.png')

const useStyles = makeStyles((theme) => ({
    legalContent: {
        maxWidth: '500px'
    },
    primaryTitlesWhite: {
        textAlign: 'center',
        paddingTop: '2em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#fafafa',
        position: 'relative'
    },
    primaryTitles: {
        textAlign: 'center',
        paddingTop: '2em',
        paddingLeft: '1em',
        paddingRight: '1em',
        // color: '#24b47e',
        color: '#199280',
        position: 'relative',
        verticalAlign: 'center'
    },
    primaryPara: {
        textAlign: 'justify',
    },
    bannerTitles: {
        textAlign: 'center',
        paddingTop: '1em',
        // color: '#24b47e',
        color: 'white',
        fontSize: '5em',
        [theme.breakpoints.down('sm')]: {
            fontSize: '3em',
            paddingTop: '0.5em',
        },
    },
    subTitles: {
        textAlign: 'justify',
        color: '#156573',
        fontSize: '1em',
        marginBottom: '1em',
        marginTop: '1em',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.5em',
            paddingTop: '0.5em',
        },
    },
    carImage: {
        backgroundImage: `url(${carImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        minHeight: '35px',
        borderRadius: '4px'
    },
    container: {
        marginBottom: '100px',
        minHeight: '87vh',
        maxWidth:'100%',
        padding: 0,
    },
    topBanner: {
        background: `linear-gradient(210deg,#8CD987 0%,#1FA595 100%)`,
        paddingBottom: '1em'
    },
    bannerIcon: {
        fontSize: '1em',
        verticalAlign: 'text-top'
    },
    txnReport: {
        maxHeight: '350px',
        boxShadow: '0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35)',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '175px'
        },
    },
    paxa: {
        top: '35%',
        marginLeft: '17em',
        position: 'absolute',
        maxHeight: '280px',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            display: 'inline-block',
            maxHeight: '135px',
            marginLeft: '3em',
            top: '0%',
            left: '0%'
        },
    },
    webCheckout: {
        top: '14%',
        marginLeft: '21em',
        position: 'absolute',
        maxHeight: '350px',
        boxShadow: '0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35)',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            display: 'inline-block',
            maxHeight: '175px',
            marginLeft: '13em',
            marginTop: '-17em',
            top: '0%',
        },
    },
    iphone: {
        zIndex: 2,
        position: 'absolute',
        width: '264px',
        height: '533px',
        padding: '8px',
        borderRadius: '36px',
        background: '#f6f9fc',
        boxShadow: '0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35)',
        userSelect: 'none',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            width: '132px',
            height: '256px',
            padding: '4px',
            fontSize: '8px',
            borderRadius: '17px',
        },
    },
    iphoneGraphic: {
        position: 'relative',
        height: '100%',
        borderRadius: '34px',
        background: '#fff',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '17px',
        },
    },
    checkoutGraphic: {
        minHeight: '24px',
        fontSize: '11px',
        height: '100%',
        padding: '24px 16px',
        borderRadius: '28px',
        background:'#fff',
        letterSpacing: '.2px',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '14px',
            letterSpacing: '.05px',
            padding: '12px 8px',
            fontSize: '7px',
            minHeight:'12px',
        },
    },
    checkoutInput: {
        minHeight: '24px',
        lineHeight: '24px',
        margin: '6px 0 16px',
        color: '#62788d',
        borderRadius: '4px',
        boxShadow: '0 0 0 1px rgba(50,50,93,.07), 0 2px 3px -1px rgba(50,50,93,.12), 0 1px 3px -1px rgba(0,0,0,.12)',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '2px',
            minHeight:'12px',
            lineHeight: '12px',
            margin: '3px 0 8px',
        },
    },
    center: {
        textAlign: 'center'
    },
    cardInput: {
        display: 'grid',
        grid: 'auto/repeat(2,1fr)',
        minHeight: '24px',
        lineHeight: '24px',
        margin: '6px 0 16px',
        color: '#62788d',
        borderRadius: '4px',
        boxShadow: '0 0 0 1px rgba(50,50,93,.07), 0 2px 3px -1px rgba(50,50,93,.12), 0 1px 3px -1px rgba(0,0,0,.12)',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '2px',
            minHeight:'12px',
            lineHeight: '12px',
            margin: '3px 0 8px',
        },
    },
    cardNumber: {
        gap: '0 6px',
        gridArea: 'auto/span 2',
        boxAlign: 'center',
        alignItems: 'center',
        paddingRight: '6px',
        borderBottom: '1px solid #e6ebf1',

        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',

        [theme.breakpoints.down('sm')]: {
            gap: '0 3px',
            paddingRight:'3px',
            paddingLeft: '4px',
        },
    },
    cardExpiry: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '4px',
        },
    },
    cardCvc: {
        paddingRight: '4px',
        borderLeft: '1px solid #e6ebf1',
        whiteSpace: 'nowrap',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '4px',
            paddingRight: '2px'
        },
    },
    cvcIcon: {
        margin: '4px 0 0 4px',
        [theme.breakpoints.down('sm')]: {
            margin: '2px 0 0 2px',
        },
    },
    country: {
        marginBottom: '24px',
        minHeight: '24px',
        lineHeight: '24px',
        margin: '6px 0 16px',
        color: '#62788d',
        borderRadius: '4px',
        boxShadow: '0 0 0 1px rgba(50,50,93,.07), 0 2px 3px -1px rgba(50,50,93,.12), 0 1px 3px -1px rgba(0,0,0,.12)',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '12px',
            minHeight: '12px',
            lineHeight: '12px',
            margin: '3px 0 8px',
            borderRadius: '2px',
        },
    },
    countryDropdown: {
        boxAlign: 'center',
        alignItems: 'center',
        paddingRight: '9px',
        borderBottom: '1px solid #e6ebf1',

        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '4px',
            paddingLeft: '4px',
        },
    },
    addressInput: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',
        borderBottom: '1px solid #e6ebf1',
        marginTop: '4px'
    },
    payBtn: {
        borderRadius: '4px',
        padding: '5px 0',
        textAlign: 'center',
        fontWeight: 425,
        color: '#fff',
        background: '#156573',
        boxShadow: '0 2px 4px -1px rgba(50,50,93,.25), 0 1px 3px -1px rgba(0,0,0,.3)',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '2px',
            padding: '2px 0',
        },
    },
    secondLayer: {
        marginTop: '5em',
        backgroundColor: '#25a794',
        color: '#fafafa',
        padding: '7em',
        [theme.breakpoints.down('sm')]: {
            padding: '3em',
        },
    },
    demoContainer: {
        padding: '1em',
        maxHeight: '35rem',
        margin: '0 auto'
    },
    codeBlock: {
        borderRadius: '14px',
        margin: '0 auto'
    }
}));

const PrivacyPolicy = (props) => {
    const classes = useStyles(props)
    const { toggleNavbarWhite, toggleNavbarTransparent } = props
    useLayoutEffect(() => {
        toggleNavbarWhite(true)
        hljs.registerLanguage('javascript', javascript);
        hljs.initHighlightingOnLoad();

    },[])

    return (<Container className={classes.container} >
        <Grid container 
            justify="center" 
            alignContent="center" 
            alignItems="center" 
            direction="row"
            className={classes.content}
        >
            <Grid item xs={12} sm={12} md={12} className={classes.topBanner}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.bannerTitles} variant="h4">
                            Privacy Policy
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.legalContent}>
                <Grid container>
                    {/* Introduction */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            Hello There!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.primaryPara} variant="h7">
                            Liberalize provides a holistic eco-system for payments. Businesses of all sizes use our software and services to accept payments and manage their businesses online and offline. Liberalize cares about the security and privacy of the personal data that is entrusted to us.
                        </Typography><br/><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            This policy describes the Personal Data that we collect, how we use and share it, your rights and choices, and how you can contact us about our privacy practices.
                        </Typography><br/><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            "Liberalize", "us", "we" or "our" in this Privacy Statement refers to Liberalize, which is responsible for processing your personal information. While this Privacy Statement applies to Liberalize in Singapore, depending on your country, the Privacy Policy differs. Please contract us at support@liberalize.io for more information.
                        </Typography><br/><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            By accessing our Website, submitting your Personal Data, or by engaging or using our Apps, tools, and services or Platforms, you accept and consent to the practices describes described within this Privacy Statement, including the purposes for which your Personal Data will be collected, used, and disclosed as set out in this Privacy Statement. 
                        </Typography>
                    </Grid><br/><br/>
                    {/* Overview */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            1. Overview
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.primaryPara} variant="h7">
                            Liberalize obtains Personal Data about you from various sources. “You” may be a visitor to one of our Sites (“Visitor”), a user of one or more of our Services (“User” or Liberalize User”), or a direct or indirect customer of a User (“Customer”). If you are a Customer, your agreement with the relevant Liberalize User should explain how the Liberalize User shares your Personal Data with Liberalize. If you have questions about this sharing, then you should direct those questions to the Liberalize User.
                        </Typography>
                    </Grid><br/><br/>
                    {/* Personal Data We Collect */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            2. Personal Data We Collect
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            a. Personal Data that we collect about you
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.primaryPara} variant="h7">
                            Personal Data is any information that relates to an identified or identifiable individual, and can include information about how you engage with our Services (e.g. device information, IP Address). In many cases, the Personal Data that you provide directly to us through our Services will be apparent from the context in which you provide the data:
                        </Typography><br/><br/>
                        <ul>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    When you register for a Liberalize account on our Site we collect your full name, email address, and account log-in credentials.
                                </li>
                            </Typography>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    When you fill-in our online form to prior to activating your account, we ask for your name, contact information, country, and other information about your interest in our Services.
                                </li>
                            </Typography>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    When you authorize us to store information about you in connection with Liberalize Payments Forms, we collect your name and information about your stored payment methods (e.g. payment card number, CVC code and expiration date).
                                </li>
                            </Typography>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    When you submit your ID for purposes of verification.
                                </li>
                            </Typography>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    When you respond to Liberalize emails or surveys, we collect your email address, name and any other information you choose to include in the body of your email or responses. If you contact us by phone, we will collect the phone number you use to call Liberalize, as well as other information you may provide during the call. If you are a Liberalize User or Customer, when you contact us, we may collect additional information in order to verify your identity.
                                </li>
                            </Typography>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    If you are a Liberalize User, you will provide your contact details, such as name, postal address, telephone number, and email address. As part of your business relationship with us, we may also collect financial and personal information about you, such as your date of birth and government identifiers associated with you and your organization (such as your social security number, tax number, or Employer Identification Number). You may also choose to provide bank account information.
                                </li>
                            </Typography>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    If you are a Customer, when you make payments to, or transact with a User through Liberalize's Services or a Liberalize provided devices, we will receive your transaction information. If you are transacting directly with Liberalize, we receive the information directly from you. If you are transacting with a User, depending on how they integrated our Services, we may receive this information directly from you, from the Liberalize User or third parties. The information that we collect will include payment method information (such as credit or debit card number, or bank account information), purchase amount, date of purchase, and in some cases, some information about your purchases. Different payment methods may require the collection of different categories of information. The payment method information that we collect will depend upon the payment method that you choose to use from the list of available payment methods that are offered to you at the time of check-out. We may also receive your name, email, billing or shipping address and in some cases, your transaction history to authenticate you.
                                </li>
                            </Typography>
                        </ul><br/><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            In connection with fraud monitoring, prevention, detection, and compliance activities for Liberalize and its Users, we receive identity related from the following sources:
                        </Typography><br/><br/>
                        <ul>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    From Customers (including through their devices (e.g. IP Addresses)) related to Customers.
                                </li>
                            </Typography>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    From Users about themselves and their Customers, including as collected through our Services.
                                </li>
                            </Typography>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    From our business partners, financial service providers, identity verification services, and publicly available sources.
                                </li>
                            </Typography>
                        </ul><br/><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            This Personal Data (e.g., name, address, phone number, country) helps us to confirm identities and prevent fraud. We may also use technology to assess the fraud risk associated with an attempted transaction by a Customer with a Liberalize User.
                        </Typography><br/><br/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            b. Information that we collect automatically on our Sites and through marketing of our products
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.primaryPara} variant="h7">
                            Our Sites and services may use cookies and other technologies. These technologies record information about you, including:
                        </Typography><br/><br/>
                        <ul>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    Browser and device data, such as IP Address, device type, operating system and Internet browser type, screen resolution, operating system name and version, device manufacturer and model, language, plug-ins, add-ons and the language version of the Sites you are visiting.
                                </li>
                            </Typography>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                Usage data, such as time spent on the Sites, pages visited, links clicked, language preferences, and the pages that led or referred you to our Sites.
                                </li>
                            </Typography>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    Online activities. We collect information about your online activities on websites and connected devices over time and across third-party websites, devices, apps and other online services.
                                </li>
                            </Typography>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    We collect information when you engage with our marketing messages and when you click on links included in ads for our products. We use Google Analytics on our Sites to help us analyze your use of our Sites and diagnose technical issues.
                                </li>
                            </Typography>
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            3. How We Use Personal Data
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.primaryPara} variant="h7">
                            We process personal information only when we have a valid legal ground to do so. Most commonly, we use your personal information where:
                        </Typography><br/><br/>
                        <ul>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    It is necessary to process your personal information in connection with the performance of a contract that we have with you directly or indirectly.
                                </li>
                            </Typography>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    It is necessary for our legitimate interests (or the legitimate interests of a third party). We make sure that your fundamental rights are not overridden by our legitimate interests.
                                </li>
                            </Typography>
                            <Typography className={classes.primaryPara} variant="h7">
                                <li>
                                    We use your personal information to comply with our legal obligations – for example, ensuring that we use your personal information to comply with anti-bribery and anti-money laundering requirements;
                                </li>
                            </Typography><br/><br/>
                        </ul>
                        <Typography className={classes.primaryPara} variant="h7">
                                We may process your data for the purspose of:
                        </Typography><br/><br/>
                        <ul>
                            <Typography className={classes.subTitles} variant="h7">
                                <li>
                                    a. Verify, authenticate and authorize your use of our products or services
                                </li>
                            </Typography><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                To conduct ‘Know your Customer’ and risk assessments in order to authenticate and authorize your use of our products or services depending on if you are a merchant, consumer or customer, and your choice of service or product. The type of personal information typically required is Identity, Contact and Financial information. This is necessary in order for Liberalize to assess your application under contract and necessary for our legal obligations under certain laws.
                            </Typography><br/><br/>
                            <Typography className={classes.subTitles} variant="h7">
                                <li>
                                    b. Process payment transactions made through our Platforms
                                </li>
                            </Typography><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                The types of personal information we require to provide a product or service and the legal ground depends on the specific payment methods made available by us.
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                In order complete the payment by you to the merchant to purchase a product or service. Liberalize often processes personal information on behalf of the merchant, acting as the party responsible. In other cases, certain payment transactions require you to provide personal information directly to us onto our Platform in order for us to process a transactions – in that instance the purpose is to process the payment for you.
                            </Typography><br/><br/>
                            <Typography className={classes.subTitles} variant="h7">
                                <li>
                                    c. Protect our business and to ensure compliance with the law
                                </li>
                            </Typography><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                We process personal information to meet the requirements of applicable laws, regulations, standards, rules, codes and the requirements of financial institutions with which Liberalize must comply. This includes:
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                I. Authenticating and validating payments to mitigate and protect against identity theft or fraud. To do this, some of your personal and non-personal information may be collected by Liberalize directly or shared to Liberalize by merchants. Liberalize will use this personal information to enter into the Liberalize fraud systems available for this validation and will remain there for future reference and cross-reference of information required to validate the payments.
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                II. Consulting and reporting your personal information and behaviour on monetary obligations to legitimately constituted credit, financial, commercial or service risk centers, or to other financial institutions, under applicable law.
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                III. Verifying your identity and comparing your information to verify accuracy for reporting obligations under applicable law or payment scheme rules.
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                IV.  Processing your personal information if you exert your right of refusal on purchases made or if your transaction is, for example, the subject of payments dispute or chargeback with a financial institution.
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                V.  Processing your personal information to ensure business continuity of our businesses and appropriate disaster recovery for our Websites, Apps, Platforms, services and products.
                            </Typography><br/><br/>
                            <Typography className={classes.subTitles} variant="h7">
                                <li>
                                    d. Manage our relationship with you
                                </li>
                            </Typography><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                If you contact us or otherwise give us your Contact information (for example by registering, by completing an enquiry form on our Website/s, or by subscribing to receive support, and service status communications from us or security or fraud monitoring alerts), we may process your personal information:
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                I. To inform you about your products or services with us and any changes to these products or services and any associated legal documents.
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                II. To notify you if there is any interruption of services or products.
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                III. To ask you to provide information on how we can improve or develop services or products and to otherwise effectively communicate with you.
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                IV. To provide you with service assistance and problem solutions or to contact or send you notifications related specifically to the services or products we offer you.
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                V. To use your personal information in transactional or fraud monitoring reports (or both) as part of the performance of our contract. You have the option to unsubscribe from such reports in accordance with the terms of our contract. Be sure to check with your finance team before you unsubscribe.
                            </Typography><br/><br/>
                            <Typography className={classes.subTitles} variant="h7">
                                <li>
                                    e. Market our products and services and related services to you
                                </li>
                            </Typography><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                We may use personal information to market our products and services and to notify you about events, offers, sponsorships, marketing programmes and similar marketing campaigns.
                            </Typography><br/><br/>
                            <Typography className={classes.subTitles} variant="h7">
                                <li>
                                    f. Conduct research and to develop and improve our products and services
                                </li>
                            </Typography><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                We may use personal information that we collect:
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                I. To research and gain insights into market trends and needs and to develop or innovate our technologies, products and services to meet such market trends and needs. We may use machine learning and artificial intelligence techniques to conduct research to gain such insights.
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                II. To analyze visitor use of our Websites, Apps, products or services in line with this Privacy Statement.
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                III. To improve and personalize our merchant and customer relationships.
                            </Typography><br/><br/>
                            <Typography className={classes.primaryPara} variant="h7">
                                IV. To provide merchants with statistical insights and reports based on data we received.
                            </Typography><br/><br/>
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            4. How We Disclose Personal Data
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.primaryPara} variant="h7">
                            We share your Personal Data with trusted entities, as outlined below.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            a. Liberalize
                        </Typography><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            We share Personal Data with other Liberalize affiliated entities in order to provide our Services and for our administration purposes.
                        </Typography><br/><br/>
                        <Typography className={classes.subTitles} variant="h5">
                            b. Service providers
                        </Typography><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            We share Personal Data with certain of our service providers subject to contract terms that limit their use of Personal Data. We have service providers that provide services on our behalf, such as identity verification services, website hosting, data analysis, marketing service, information technology and related infrastructure, customer service, email delivery, and auditing services. These service providers may need to access Personal Data to perform their services. We authorize such service providers to use or disclose the Personal Data only to perform services on our behalf or comply with legal requirements. We require such service providers to contractually commit to protect the security and confidentiality of Personal Data they process on our behalf. Our service providers are predominantly located in Singapore.
                        </Typography><br/><br/>
                        <Typography className={classes.subTitles} variant="h5">
                            c. Business partners
                        </Typography><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            We share Personal Data with third party business partners in connection with our Services to our Users. Examples of third parties to whom we may disclose Personal Data for this purpose are banks and payment method providers (such as credit card networks) when we provide payment processing services, and the professional services firms that we partner with.
                        </Typography><br/><br/>
                        <Typography className={classes.subTitles} variant="h5">
                            d. Our Users and third parties authorized by our Users
                        </Typography><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            We share Personal Data with Users to maintain a User account and provide the Services and third party services. We share data with parties directly authorized by a User to receive Personal Data. This includes sharing Personal Data of Customers with Users to assess the fraud risk associated with attempted transactions by Customers, or when a User authorizes a third party application provider to access the User’s Liberalize account using any Liberalize services. The use of Personal Data by an authorized third party is subject to the third party’s privacy policy.
                        </Typography><br/>
                        <Typography className={classes.subTitles} variant="h5">
                            e. Corporate transactions
                        </Typography><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            In the event that we enter into, or intend to enter into, a transaction that alters the structure of our business, such as a reorganization, merger, sale, joint venture, assignment, transfer, change of control, or other disposition of all or any portion of our business, assets or stock, we may share Personal Data with third parties in connection with such transaction. Any other entity which buys us or part of our business will have the right to continue to use your Personal Data, but subject to the terms of this Privacy Policy.
                        </Typography><br/><br/>
                        <Typography className={classes.subTitles} variant="h5">
                            f. Compliance and harm prevention
                        </Typography><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            We share Personal Data as we believe necessary: (i) to comply with applicable law, or rules imposed by payment method in connection with use of that payment method; (ii) to enforce our contractual rights; (iii) to protect the Services, rights, privacy, safety and property of Liberalize, you or others; and (iv) to respond to requests from courts, law enforcement agencies, regulatory agencies, and other public and government authorities, which may include authorities outside your country of residence.
                        </Typography><br/><br/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            5. Security and Retention
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.primaryPara} variant="h7">
                            We make reasonable efforts to provide a level of security appropriate to the risk associated with the processing of Personal Data. We maintain organizational, technical and administrative measures designed to protect Personal Data covered by this Privacy Policy against unauthorized access, destruction, loss, alteration or misuse. Your Personal Data is only accessed by a limited number of personnel who need access to the information to perform their duties. Unfortunately, no data transmission or storage system can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure (e.g., you feel that the security of your account has been compromised), please contact us immediately.
                        </Typography><br/><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            We retain your Personal Data as long as we are providing the Services to you or our Users (as applicable). Even after we stop providing Services directly or indirectly to you, and even if you close your Liberalize account or complete a transaction with a Liberalize User, we keep your Personal Data in order to comply with our legal and regulatory obligations. We may also keep it to assist with our fraud monitoring, detection and prevention activities. We also keep Personal Data to comply with our tax, accounting, and financial reporting obligations, where we are required to retain the data by our contractual commitments to our financial partners, and where data retention is mandated by the payment methods you used. In all cases where we keep data, we do so in accordance with any limitation periods and records retention obligations that are imposed by applicable law.
                        </Typography><br/><br/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            7. International Data Transfers
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.primaryPara} variant="h7">
                            We are a global business. Personal Data may be stored and processed in any country where we do business or our service providers do business. We may transfer your Personal Data to countries other than your own country. These countries may have data protection rules that are different from your country. When transferring data across borders, we take measures to comply with applicable data protection laws related to such transfer. Officials (such as law enforcement or security authorities) in those other countries may be entitled to access your Personal Data.
                        </Typography><br/><br/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            8. Use by Minors
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.primaryPara} variant="h7">
                            Liberalize does not voluntarily or actively collect, use or disclose personal information of minors without the prior consent of the parents or guardians of the minor. The services of Liberalize are not intended or designed to attract minors.
                        </Typography><br/><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            If we learn that we collected the personal information of a minor, without first receiving a verifiable parental consent, we will take steps to delete the information as soon as possible.
                        </Typography><br/><br/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            9. Updates To this Privacy Policy and Notifications
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.primaryPara} variant="h7">
                            We may change this Privacy Policy from time to time to reflect new services, changes in our Personal Data practices or relevant laws. 
                        </Typography><br/><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            We may provide you with disclosures and alerts regarding the Privacy Policy or Personal Data collected by posting them on our website and, if you are a User, by contacting you through your Liberalize Dashboard, email address and/or the physical address listed in your Liberalize account.
                        </Typography><br/><br/>
                        <Typography className={classes.primaryPara} variant="h7">
                            If applicable law requires that we obtain your consent or provide notice in a specified manner prior to making any changes to this Privacy Policy applicable to you, we will provide such required notice and will obtain your required consent.
                        </Typography><br/><br/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            10. How To Contact Us
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.primaryPara} variant="h7">
                            The Webmaster of this Website is Liberalize and if you have any additional questions or concerns about the content of this Privacy Statement or would like to make a request, please feel free to contact us at support@liberalize.io.
                        </Typography><br/><br/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Container>)
}

export default PrivacyPolicy
