import React, {useLayoutEffect, useEffect} from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import carImage from "../assets/greenlambo.jpg"
import clsx from 'clsx';

import Highlight from 'react-highlight'
import hljs from 'highlight.js';
import javascript from 'highlight.js/lib/languages/javascript';
import 'highlight.js/styles/vs2015.css';

const webCheckoutSrc = require('../assets/webCheckout.jpg')
const paxa = require('../assets/paxa.png')
const txnReport = require('../assets/txnReport.png')

const useStyles = makeStyles((theme) => ({
    legalContent: {
        maxWidth: '500px'
    },
    primaryTitlesWhite: {
        textAlign: 'center',
        paddingTop: '2em',
        paddingLeft: '1em',
        paddingRight: '1em',
        color: '#fafafa',
        position: 'relative'
    },
    primaryTitles: {
        textAlign: 'center',
        paddingTop: '2em',
        paddingLeft: '1em',
        paddingRight: '1em',
        // color: '#24b47e',
        color: '#199280',
        position: 'relative',
        verticalAlign: 'center'
    },
    primaryPara: {
        textAlign: 'justify',
    },
    bannerTitles: {
        textAlign: 'center',
        paddingTop: '1em',
        // color: '#24b47e',
        color: 'white',
        fontSize: '5em',
        [theme.breakpoints.down('sm')]: {
            fontSize: '3em',
            paddingTop: '0.5em',
        },
    },
    subTitles: {
        textAlign: 'justify',
        color: '#156573',
        fontSize: '1em',
        marginBottom: '1em',
        marginTop: '1em',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.5em',
            paddingTop: '0.5em',
        },
    },
    carImage: {
        backgroundImage: `url(${carImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        minHeight: '35px',
        borderRadius: '4px'
    },
    container: {
        marginBottom: '100px',
        minHeight: '87vh',
        maxWidth:'100%',
        padding: 0,
    },
    topBanner: {
        background: `linear-gradient(210deg,#8CD987 0%,#1FA595 100%)`,
        paddingBottom: '1em'
    },
    bannerIcon: {
        fontSize: '1em',
        verticalAlign: 'text-top'
    },
    txnReport: {
        maxHeight: '350px',
        boxShadow: '0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35)',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '175px'
        },
    },
    paxa: {
        top: '35%',
        marginLeft: '17em',
        position: 'absolute',
        maxHeight: '280px',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            display: 'inline-block',
            maxHeight: '135px',
            marginLeft: '3em',
            top: '0%',
            left: '0%'
        },
    },
    webCheckout: {
        top: '14%',
        marginLeft: '21em',
        position: 'absolute',
        maxHeight: '350px',
        boxShadow: '0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35)',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            display: 'inline-block',
            maxHeight: '175px',
            marginLeft: '13em',
            marginTop: '-17em',
            top: '0%',
        },
    },
    iphone: {
        zIndex: 2,
        position: 'absolute',
        width: '264px',
        height: '533px',
        padding: '8px',
        borderRadius: '36px',
        background: '#f6f9fc',
        boxShadow: '0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35)',
        userSelect: 'none',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            width: '132px',
            height: '256px',
            padding: '4px',
            fontSize: '8px',
            borderRadius: '17px',
        },
    },
    iphoneGraphic: {
        position: 'relative',
        height: '100%',
        borderRadius: '34px',
        background: '#fff',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '17px',
        },
    },
    checkoutGraphic: {
        minHeight: '24px',
        fontSize: '11px',
        height: '100%',
        padding: '24px 16px',
        borderRadius: '28px',
        background:'#fff',
        letterSpacing: '.2px',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '14px',
            letterSpacing: '.05px',
            padding: '12px 8px',
            fontSize: '7px',
            minHeight:'12px',
        },
    },
    checkoutInput: {
        minHeight: '24px',
        lineHeight: '24px',
        margin: '6px 0 16px',
        color: '#62788d',
        borderRadius: '4px',
        boxShadow: '0 0 0 1px rgba(50,50,93,.07), 0 2px 3px -1px rgba(50,50,93,.12), 0 1px 3px -1px rgba(0,0,0,.12)',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '2px',
            minHeight:'12px',
            lineHeight: '12px',
            margin: '3px 0 8px',
        },
    },
    center: {
        textAlign: 'center'
    },
    cardInput: {
        display: 'grid',
        grid: 'auto/repeat(2,1fr)',
        minHeight: '24px',
        lineHeight: '24px',
        margin: '6px 0 16px',
        color: '#62788d',
        borderRadius: '4px',
        boxShadow: '0 0 0 1px rgba(50,50,93,.07), 0 2px 3px -1px rgba(50,50,93,.12), 0 1px 3px -1px rgba(0,0,0,.12)',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '2px',
            minHeight:'12px',
            lineHeight: '12px',
            margin: '3px 0 8px',
        },
    },
    cardNumber: {
        gap: '0 6px',
        gridArea: 'auto/span 2',
        boxAlign: 'center',
        alignItems: 'center',
        paddingRight: '6px',
        borderBottom: '1px solid #e6ebf1',

        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',

        [theme.breakpoints.down('sm')]: {
            gap: '0 3px',
            paddingRight:'3px',
            paddingLeft: '4px',
        },
    },
    cardExpiry: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '4px',
        },
    },
    cardCvc: {
        paddingRight: '4px',
        borderLeft: '1px solid #e6ebf1',
        whiteSpace: 'nowrap',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '4px',
            paddingRight: '2px'
        },
    },
    cvcIcon: {
        margin: '4px 0 0 4px',
        [theme.breakpoints.down('sm')]: {
            margin: '2px 0 0 2px',
        },
    },
    country: {
        marginBottom: '24px',
        minHeight: '24px',
        lineHeight: '24px',
        margin: '6px 0 16px',
        color: '#62788d',
        borderRadius: '4px',
        boxShadow: '0 0 0 1px rgba(50,50,93,.07), 0 2px 3px -1px rgba(50,50,93,.12), 0 1px 3px -1px rgba(0,0,0,.12)',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '12px',
            minHeight: '12px',
            lineHeight: '12px',
            margin: '3px 0 8px',
            borderRadius: '2px',
        },
    },
    countryDropdown: {
        boxAlign: 'center',
        alignItems: 'center',
        paddingRight: '9px',
        borderBottom: '1px solid #e6ebf1',

        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '4px',
            paddingLeft: '4px',
        },
    },
    addressInput: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'column',
        paddingLeft: '8px',
        borderBottom: '1px solid #e6ebf1',
        marginTop: '4px'
    },
    payBtn: {
        borderRadius: '4px',
        padding: '5px 0',
        textAlign: 'center',
        fontWeight: 425,
        color: '#fff',
        background: '#156573',
        boxShadow: '0 2px 4px -1px rgba(50,50,93,.25), 0 1px 3px -1px rgba(0,0,0,.3)',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '2px',
            padding: '2px 0',
        },
    },
    secondLayer: {
        marginTop: '5em',
        backgroundColor: '#25a794',
        color: '#fafafa',
        padding: '7em',
        [theme.breakpoints.down('sm')]: {
            padding: '3em',
        },
    },
    demoContainer: {
        padding: '1em',
        maxHeight: '35rem',
        margin: '0 auto'
    },
    codeBlock: {
        borderRadius: '14px',
        margin: '0 auto'
    }
}));

const PrivacyPolicy = (props) => {
    const classes = useStyles(props)
    const { toggleNavbarWhite, toggleNavbarTransparent } = props
    useLayoutEffect(() => {
        toggleNavbarWhite(true)
        hljs.registerLanguage('javascript', javascript);
        hljs.initHighlightingOnLoad();

    },[])

    return (<Container className={classes.container} >
        <Grid container 
            justify="center" 
            alignContent="center" 
            alignItems="center" 
            direction="row"
            className={classes.content}
        >
            <Grid item xs={12} sm={12} md={12} className={classes.topBanner}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.bannerTitles} variant="h4">
                            Terms Of Use
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.legalContent}>
                <Grid container>
                    {/* Introduction */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            LIBERALIZE’S WEBSITE: GENERAL DISCLAIMER AND TERMS OF USE
                        </Typography>
                    </Grid><br/><br/><br/>
                    {/* General */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            General
                        </Typography>
                    </Grid><br/><br/><br/>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            The following Terms of Use is a legal agreement between you and Liberalize Pte. Ltd. (“Liberalize” or “the Company”) governing the use of the website located at www.liberalize.io and all sites linked to it (“Website”). By accessing, browsing or using this Website, you acknowledge that you have read, understood and agreed to be bound by these terms and comply with all applicable laws and regulations. If you do not agree with these terms, you are not authorized to access and use the Website and should exit this Website immediately.
                        </Typography><br/><br/>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            Liberalize may, at its sole discretion, amend this Terms of Use from time to time and revise any other information contained in this Website without prior notice. By virtue of your continued use of this Website following any such changes, it indicates you have consented to be bound by the amended Terms of Use. Liberalize reserves its rights to terminate your access to this Website at any time without notice and without providing any reason.
                        </Typography><br/><br/>
                    </Grid><br/><br/>
                    {/* Disclaimers */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            Disclaimers
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            The information and material contained in or accessed through this Website are provided on an “as-is” and “as available” basis and are of a general nature. Although Liberalize has taken care to present such information and material obtained from reliable sources, however such contents have not been verified, considered or assessed by Liberalize. It is your responsibility to verify and confirm the accuracy and completeness of all information posted within this Website prior to relying on or taking any action.
                        </Typography><br/><br/>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            The information and materials contained in or accessed through this Website is not intended to offer solutions to problems, nor should they be regarded as substitute for professional or any kind of advice from Liberalize. Such information and materials are provided for general information only and you should seek independent consultation at all times and obtain independent verification of the information and materials contained herein.
                        </Typography><br/><br/>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            Under no circumstances shall Liberalize be liable for any warranties of any kind in relation to the Website, its content, service or features, whether express or implied or statutory, including but not limited to warranties of accuracy, reliability, merchantability, non-infringement and fitness for a particular purpose.
                        </Typography><br/><br/>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            Liberalize does not warrant the truth, accuracy, adequacy, completeness or reasonableness of the information and materials contained in or accessed through this Website and expressly disclaim liability for any errors in, or omission from, such information and materials. Liberalize further does not warrant that the functions contained in the materials will be uninterrupted or error-free, that defects will be corrected or that this Website or this server that makes it available is free of any virus or other harmful elements.
                        </Typography><br/><br/>
                    </Grid><br/><br/>
                    {/* Limitation of Liability */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            Limitation of Liability
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            In no event will Liberalize, or its parent, subsidiaries, affiliates, officers, directors, employees, contractors, subcontractors, agents or representatives, be liable for any direct, indirect, incidental, punitive, special, exemplary or consequential damages of any type whatsoever related to or arising from this Website or any use of this Website, or inability to use, or of any site or resource linked to, referenced, or accessed through this Website, or for the use or downloading of, or access to, any materials, information, products, or services, including without limitation, any lost profits, business interruption, lost savings or loss of programs or other data, even if Liberalize is expressly advised of the possibility of such damages. This exclusion and waiver of liability applies to all causes of action, whether based on contract, warranty, tort, or any other legal theories. The foreign limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction.
                        </Typography><br/><br/>
                    </Grid><br/><br/>
                    {/* Indemnity */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            Indemnity
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            You agree to defend, indemnify and hold harmless Liberalize, and its parent, subsidiaries, officers, directors, employees, contractors, subcontractors and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including legal fees on a full indemnity basis) arising from:
                        </Typography><br/><br/>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            (i) Your use of and access to the Website.
                        </Typography><br/><br/>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            (ii) Your violation of any term of these Terms of Use.
                        </Typography><br/><br/>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            (iii) Your violation of any third party rights, including without limitation any copyright, trademark, property, publicity or privacy right. This defense and indemnification obligation will survive these Terms of Use and your use of the Website.
                        </Typography><br/><br/>
                    </Grid><br/><br/>
                    {/* Intellectual Property Rights */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            Intellectual Property Rights
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            This Website may contain certain proprietary and copyright information of Liberalize and its licensors. You acknowledge and agree to comply with the terms and conditions associated with the attached copyright and other proprietary rights of Liberalize and its licensors with regards to the content of this Website, as may be amended from time to time.
                        </Typography><br/><br/>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            All content on the Website including but not limited to artwork, photographs, computer code, the “look and feel” of the data and content is owned and controlled by Liberalize and/or its licensors and is protected by copyright, trademark, and other proprietary rights and laws of Singapore and other countries.
                        </Typography><br/><br/>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            All trademarks, service marks and logos contained in this Website are the property of Liberalize and/or the respective third party proprietors. No license or right is granted. Your access to this Website shall not be construed as granting, by implication, estoppel or otherwise, any license or right to use any trade mark, service marks or logos appearing on the Website without prior written consent of Liberalize and/or the relevant third party proprietor thereof.
                        </Typography><br/><br/>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            Any unauthorised use/modification of the content, data, trademarks, service marks and logos on this Website may constitute an infringement of these laws and the rights vested in Liberalize, licensors and/or the relevant third party proprietor and Liberalize, licensors and/or the relevant third party proprietor shall reserve all its rights and remedy as provided in law or at equity.
                        </Typography><br/><br/>
                    </Grid><br/><br/>
                    {/* Restrictions on use of materials */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            Restrictions On Use Of Materials
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            You shall not modify, reproduce, upload, display publicly, republish or distribute the content of this Website in any way whatsoever nor shall they use it, in any manner for public or commercial purposes. You may print, copy and download the content of this Website for personal, non-commercial use only provided you keep intact all copyright and other proprietary notices contained in the content of this Website and do not modify any of the content. Graphics and images on this Website are protected by copyright and may not be reproduced or appropriated in any manner without the prior consent of the relevant owners. Use of the content of this Website on any website or other flat surface media or internet computer environment for any purpose is strictly prohibited. If you breach any of these terms and conditions, you must immediately destroy any downloaded and printed materials.
                        </Typography><br/><br/>
                    </Grid><br/><br/>
                    {/* Links to other websites */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            Links To Other Websites
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            The Website may contain links to other websites which are not maintained by Liberalize. Similarly, other websites may contain links to this Website. Any use of such third party websites is entirely at the risk of the user. In no event shall Liberalize be responsible for the contents of those websites and shall not be liable for any loss, damages or injury arising from the access to and use of contents of those websites. Any links to other websites are provided solely for the convenience of the user.
                        </Typography><br/><br/>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            You agree that Liberalize does not endorse or make any representation about such third party websites and expressly disclaims any liability for the information, products or services found on them. If you use these links, you agree that Liberalize does not express any opinion on nor is it responsible for the content of any such third party websites.
                        </Typography><br/><br/>
                    </Grid><br/><br/>
                    {/* Undertaking */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            Undertaking
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography align="justify" className={classes.primaryPara} variant="h7">
                            By accessing or use this Website, you undertake:
                        </Typography><br/><br/>
                        <ul>
                            <Typography align="justify" className={classes.primaryPara} variant="h7">
                                <li>
                                    Not to use this Website in any manner which breaches any applicable law or regulation or cause or which may cause an infringement of any third party rights.
                                </li>
                                <li>
                                    Not to post, transmit or disseminate any information on or via this Website which may be harmful, obscene, defamatory or illegal or create liability on Liberalize’s part.
                                </li>
                                <li>
                                    Not interfere or attempt to interfere with the operation or functionality of this Website.
                                </li>
                                <li>
                                    Not to obtain or attempt to obtain unauthorized access, via whatever means, to any of Liberalize’s systems.
                                </li>
                            </Typography>
                        </ul>
                    </Grid><br/><br/>
                    {/* Miscellaneous */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className={classes.subTitles} variant="h5">
                            Miscellaneous
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <ul>
                            <Typography align="justify" className={classes.primaryPara} variant="h7">
                                <li>
                                    You agree to use this Website in accordance with these Terms of Use and for lawful and proper purposes only.
                                </li>
                                <li>
                                    If Liberalize (in its sole and absolute discretion) believes that you are in breach, or will be in breach, of these Terms of Use and/or any other applicable laws and regulations, Liberalize reserves its right to deny you access to this Website without giving you a reason and/or without reference to you.
                                </li>
                                <li>
                                    This Terms of Use constitutes the entire agreement between you and Liberalize with respect to the subject matter hereof and all prior oral or written agreements or understandings previously existing are hereby superseded and cancelled. No waiver of any provision in the Terms of Use shall constitute a waiver of any other provision(s) or of the same provision on another occasion.
                                </li>
                                <li>
                                    This Terms of Use and the relationship between Liberalize and you shall be governed by the laws of the Republic of Singapore and the courts of the Republic of Singapore will have exclusive jurisdiction in the event of any dispute.
                                </li>
                            </Typography>
                        </ul>
                    </Grid><br/><br/>
                </Grid>
            </Grid>
        </Grid>
    </Container>)
}

export default PrivacyPolicy
