import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom'
import './index.css';
import App from './App';
import configureStore from './app/configureStore';
import * as serviceWorker from './serviceWorker';
import DrawerState from './containers/DrawerState'
import Navbar from './containers/Navbar'
import Footer from './components/Footer'
// import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// const theme = createMuiTheme();
const store = configureStore({ navDrawers: { isOpen: false} })
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Navbar/>
      <DrawerState/>
      <App />
      <Footer/>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
