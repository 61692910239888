import { connect } from 'react-redux'
import { toggleNavbarTransparent } from '../actions/navbar'
import NoMatch from '../components/NoMatch'

const mapStateToProps = state => ({
    // openDrawer: state.navDrawers.isOpen,
    // originalNavState: (state.navBar && state.navBar.originalNav) || false
})

const mapDispatchToProps = dispatch => ({
    toggleNavbarTransparent: () => dispatch(toggleNavbarTransparent()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NoMatch)