"user strict"

import { connect } from 'react-redux';
import { toggleDrawer } from '../actions/drawers.js';
import AppDrawer from '../components/AppDrawer';

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen
})
  
const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppDrawer)