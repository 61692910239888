import React, { useEffect } from 'react';
// import './App.css';
import { connect } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router-dom'
import { makeStyles, createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import clsx from 'clsx';
import ReactGA from "react-ga4";

import NoMatch from './containers/NoMatch'
import Home from './containers/Home'
import OurCompany from './containers/Company'
import Pricing from './containers/Pricing'
import Products from './containers/Products'
import ComingSoon from './containers/ComingSoon'
import PrivacyPolicy from './containers/PrivacyPolicy'
// import GettingStarted from './containers/GettingStarted'
import Terms from './containers/Terms'
import { drawerWidth } from './app/globals';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme)

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: +drawerWidth,
  },
}));

function App(props) {
  const classes = useStyles(props);
  const { openDrawer } = props

  const location = useLocation();

  useEffect(() => {
    console.log('location change!: ', location);
    ReactGA.initialize("G-BVJ714N19Z");
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
    <main 
      className={clsx(classes.content, {
        [classes.contentShift]: openDrawer,
      })}
    >
      <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="/company" exact component={OurCompany}/>
        <Route path="/products" exact component={Products}/>
        <Route path="/pricing" exact component={Pricing}/>
        <Route path="/developer" exact component={ComingSoon}/>
        {/* <Route path="/support" exact component={ComingSoon}/> */}
        {/* <Route path="/getting-started" exact component={GettingStarted}/> */}
        <Route path="/privacy-policy" exact component={PrivacyPolicy}/>
        <Route path="/terms" exact component={Terms}/>
        <Route component={NoMatch} />
      </Switch>
    </main>
    </ThemeProvider>
  );
}

const mapStateToProps = state => ({
  openDrawer: state.navDrawers.isOpen
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)